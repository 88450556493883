/*
 * @Author: 王雁鹏
 * @Date: 2024-02-04 13:58:36
 * @LastEditTime: 2024-02-04 14:05:05
 * @LastEditors: 
 * @Description: 
 * @FilePath: \expressf:\web-vue\sfzngw\src\main.js
 * -------------------------------------
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import 'animate.css'
import './style/reset.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
Vue.config.productionTip = false
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
})
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
