<!--
 * @Author: 王雁鹏
 * @Date: 2024-01-10 21:07:34
 * @LastEditTime: 2024-01-19 21:31:53
 * @LastEditors: Please set LastEditors
 * @Description: 
 * @FilePath: \expressf:\web-vue\sfzn\src\App.vue
 * -------------------------------------
-->
<template>
  <div>
    <div class="page-header">
      <p class="page-header-name">
        <img src="./assets/logo.png" alt="">
      </p>
      <div class="page-nav-box">
        <HeaderNav />
      </div>
    </div>
    <div class="page-main">
      <router-view />
    </div>
    <div class="page-foot">
      <span>京ICP备2024046058号-1</span>
      <span class="page-foot-tag">
        <span @click="gourl(1)">首页</span>
        <span @click="gourl(2)">AI产品服务</span>
        <span @click="gourl(3)">RPA产品服务</span>
        <span @click="gourl(4)">data数据服务</span>
        <span @click="gourl(5)">关于我们</span>
      </span>
    </div>
  </div>
</template>

<script>
  import HeaderNav from "@/components/HeaderNav"
  export default {
    name: 'App',
    components: {
      HeaderNav,
    },
    methods: {
      gourl(i) {
        if (i == 1) {
          this.$router.push({ path: "/" })
        } else if (i == 2) {
          this.$router.push({ path: "/UniversalDocumentRecognition" })
        } else if (i == 3) {
          this.$router.push({ path: "/RPAAuditDocumentProcessingSystem" })
        } else if (i == 4) {
          this.$router.push({ path: "/DataProcessing" })
        } else {
          this.$router.push({ path: "/CompanyProfile" })
        }
      }
    }
  }
</script>

<style lang="scss">
  @import './style/basic.scss';

  body {
    margin: 0px;
    padding: 0px;
    width: 100%;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  // ------------------------页首页尾布局--------------------------
  .page-header {
    @include box-basic($Width: 100%, $Height: 80px);
    display: flex;
    width: 1400px !important;
    margin: auto;
    justify-content: space-between;
  }

  .page-main {
    @include box-basic($Width: 100%, $Height: auto);


  }

  .page-foot {
    display: flex;
    justify-content: space-around;
    align-items: center;
    @include box-title($Width: 100%, $Height: 100px);
    background: #EFF4FA;
    color: #666;
    font-family: "Alibaba PuHuiTi";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    /* 133.333% */
  }

  // -------------------------end----------------------------------

  .page-header-name {
    width: 199px;
    height: 100%;
    color: #357AF5;

    font-family: YouSheBiaoTiHei;
    font-size: 30px;
    font-weight: 400;
    line-height: 80px;
    letter-spacing: 0px;
    text-align: left;
    font-weight: bold;
  }

  .page-nav-box {

    height: 100%;
  }

  .page-foot-tag {
    span {
      cursor: pointer;
      margin: 0px 35px;
    }
  }
</style>