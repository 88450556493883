<!--
 * @Author: 王雁鹏
 * @Date: 2024-01-10 21:07:34
 * @LastEditTime: 2024-02-04 20:50:43
 * @LastEditors: Please set LastEditors
 * @Description: 
 * @FilePath: \expressf:\web-vue\sfzngw\src\components\HeaderNav.vue
 * -------------------------------------
-->
<template>
  <div class="nav">
    <div class="nav-box" v-for="(item, index) in data" @mouseenter.stop="handleOver(index)"
      @mouseleave.stop="handleOut(index)" :key="index" @click="handleClick1(index)">
      <span class="active" v-if="acitve == index"></span>
      <p class="nav-item">{{ item.name }}</p>
      <transition v-if="item.list" name="fade" enter-active-class="animate__animated animate__fadeInDownBig"
        leave-active-class="animate__animated animate__bounce">
        <div>
          <ul class="nav-list" v-if="show[index]">
            <li v-for="(cItem, i) in item.list " @click.stop="handleClick(cItem)" :key="i">
              <img v-if="cItem.src" :src="require('@/assets/' + cItem.src)" alt="">
              {{ cItem.name }}
            </li>
          </ul>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  import { NavData } from "../data/data.js"
  export default {
    name: 'HeaderNav',
    data() {
      return {
        show: [],
        data: NavData,
        acitve: 0

      }
    },
    watch: {
      $route(to, from) {
        if (to.path == '/UniversalDocumentRecognition' || to.path == '/InstitutionaldocumentRecognition' || to.path == '/IdentificationIdentificationDocuments' || to.path == '/BillTypeIdentification' || to.path == '/IntelligentImageProcessing' || to.path == '/AIGCInformationExtraction' || to.path == '/AIGCTextProcessing') {
          this.acitve = 1
        } else if (to.path == '/RPAAuditDocumentProcessingSystem' || to.path == '/RPADocumentIntelligentProcessingSystem') {
          this.acitve = 2
        } else if (to.path == '/DataProcessing' || to.path == '/DataAnnotations') {
          this.acitve = 3
        } else if (to.path == '/CompanyProfile' || to.path == '/JoinUs') {
          this.acitve = 4
        } else {
          this.acitve = 0
        }
      }
    },
    props: {
    },
    methods: {
      handleOver(i) {
        this.$set(this.show, i, true)
      },
      handleOut(i) {
        this.$set(this.show, i, false)
      },
      handleClick({ url }) {
        console.log(url)
        if (url == 'RPAAuditDocumentProcessingSystem') {
          window.open('//'+'document.shufudataai.com')
        } else {
          this.$router.push(url)
        }

      },
      handleClick1(index) {
        if (index == 0) {
          this.$router.push({ path: "/" })
        }

      }
    },
    created() {
      this.show = this.data.map(() => { return false })
      console.log(this.show, this.data)
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .nav {
    width: 1000px;
    margin: auto;
    display: flex;

    >* {
      flex: 1;
      z-index: 999;
    }
  }

  .nav-item {
    cursor: pointer;
    line-height: 80px;
    font-family: Alibaba PuHuiTi;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #858585;

    &:hover {
      color: #357AF5
    }
  }

  .nav-box {
    text-align: center;
    position: relative;
  }

  .nav-list {
    display: inline-block;
    width: 200px;
    list-style: none;
    background: #fff;
    box-shadow: 0px 0px 3px #ccc;
    padding: 10px 0px;

    li {
      width: calc(100% - 20px);
      height: 48px;
      margin: auto;
      line-height: 48px;
      border-radius: 4px;
      cursor: pointer;
      text-align: left;

      img {
        margin: 0px 5px;
      }

      &:hover {
        color: #357AF5;
        background: rgba(53, 122, 245, .1);

      }
    }
  }

  .active {
    width: 57px;
    height: 4px;
    background: rgba(53, 122, 245, 1);
    display: block;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
  }

  /* .fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
} */
</style>