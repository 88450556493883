// AI产品服务导航子模块
let AI = [
  {
    src: '达观数据官网-智能文本处理专家 1.png',
    name: "通用文档识别",
    url: "UniversalDocumentRecognition"
  },
  {
    src: '达观数据官网-智能文本处理专家_bb783775 1.png',
    name: "机构文档识别",
    url: "InstitutionaldocumentRecognition"
  },
  {
    src: '达观数据官网-智能文本处理专家_bb783775 1.png',
    name: "证件类识别",
    url: "IdentificationIdentificationDocuments"
  },
  {
    src: '达观数据官网-智能文本处理专家_1ba433a9 1.png',
    name: "票据识别",
    url: "BillTypeIdentification"
  },
  {
    src: '达观数据官网-智能文本处理专家_24d82244 1.png',
    name: "图像智能处理",
    url: "IntelligentImageProcessing"
  },
  {
    src: '达观数据官网-智能文本处理专家_efc0ee21 1.png',
    name: "aigc信息抽取",
    url: "AIGCInformationExtraction"
  },
  {
    src: '达观数据官网-智能文本处理专家_7182a252 1.png',
    name: "aigc文本抽取",
    url: "AIGCTextProcessing"
  }
]

// RPA产品服务导航子模块
let RPA = [
  {
    name: "RPA单据智能处理系统",
    url: "RPAAuditDocumentProcessingSystem"
  },
  {
    name: "RPA审计文档处理系统",
    url: "RPADocumentIntelligentProcessingSystem"
  }
]

// DATA数据服务导航子模块
let DATA = [
  {
    name: "数据处理",
    url: "DataProcessing"
  },
  {
    name: "数据标注",
    url: "DataAnnotations"
  }
]
let ABOUTUS = [
  {
    name: "公司介绍",
    url: "CompanyProfile"
  },
  {
    name: "加入我们",
    url: "JoinUs"
  }
]
let NavDataFn = () => {
  return [
    { name: "首页" },
    { name: "AI产品服务", list: AI },
    { name: "RPA产品服务", list: RPA },
    { name: "DATA数据服务", list: DATA },
    { name: "关于我们", list: ABOUTUS }
  ]
}

export const NavData = NavDataFn()