/*
 * @Author: 王雁鹏
 * @Date: 2024-01-20 18:08:54
 * @LastEditTime: 2024-02-05 15:24:50
 * @LastEditors: Please set LastEditors
 * @Description: 
 * @FilePath: \expressf:\web-vue\sfzngw\src\router\index.js
 * -------------------------------------
 */
import Vue from 'vue';
import Router from 'vue-router';

const index = () => import('../page/home/index.vue');
const login = () => import('../page/home/index.vue');
// AI产品服务
const UniversalDocumentRecognition = () => import('../page/ai/UniversalDocumentRecognition.vue'); // 通用文档识别
const InstitutionaldocumentRecognition = () => import('../page/ai/InstitutionalDocumentRecognition.vue'); // 机构文档识别
const IdentificationIdentificationDocuments = () => import('../page/ai/IdentificationIdentificationDocuments.vue'); //证件类识别
const BillTypeIdentification = () => import('../page/ai/BillTypeIdentification.vue'); // 票据类识别
const IntelligentImageProcessing = () => import('../page/ai/IntelligentImageProcessing.vue'); // 图像智能处理
const AIGCInformationExtraction = () => import('../page/ai/AIGCInformationExtraction.vue'); // AIGC信息抽取
const AIGCTextProcessing = () => import('../page/ai/AIGCTextProcessing.vue'); // AIGC文本处理
// RPA产品服务
const RPAAuditDocumentProcessingSystem = () => import('../page/rpa/AuditDocumentProcessing.vue'); // RPA审计文档处理系统-登录页面
const RPADocumentIntelligentProcessingSystem = () => import('../page/rpa/IntelligentProcessingDocuments.vue'); // RPA单据智能处理系统-登录页面
// DATA数据服务
const DataProcessing = () => import('../page/data/DataProcessing.vue'); // 数据处理
const DataAnnotations = () => import('../page/data/DataAnnotations.vue'); // 数据标注
// 关于我们
const CompanyProfile = () => import('../page/about/CompanyProfile.vue'); // 公司介绍
const JoinUs = () => import('../page/about/JoinUs.vue'); // 加入我们
Vue.use(Router);
let router = new Router({
  routes: [
    {
      path: '/',
      component: login,
      name: 'index',
    },
    {
      path: '/login',
      component: index,
    },
    {
      path: '/UniversalDocumentRecognition',
      component: UniversalDocumentRecognition,
    },
    {
      path: '/InstitutionaldocumentRecognition',
      component: InstitutionaldocumentRecognition,
    },
    {
      path: '/IdentificationIdentificationDocuments',
      component: IdentificationIdentificationDocuments,
    },
    {
      path: '/BillTypeIdentification',
      component: BillTypeIdentification,
    },
    {
      path: '/IntelligentImageProcessing',
      component: IntelligentImageProcessing,
    },
    {
      path: '/AIGCInformationExtraction',
      component: AIGCInformationExtraction,
    },
    {
      path: '/AIGCTextProcessing',
      component: AIGCTextProcessing,
    },
    {
      path: '/RPAAuditDocumentProcessingSystem',
      component: RPAAuditDocumentProcessingSystem,
    },
    {
      path: '/RPADocumentIntelligentProcessingSystem',
      component: RPADocumentIntelligentProcessingSystem,
    },
    {
      path: '/DataProcessing',
      component: DataProcessing,
    },
    {
      path: '/DataAnnotations',
      component: DataAnnotations,
    },
    {
      path: '/CompanyProfile',
      component: CompanyProfile,
    },
    {
      path: '/JoinUs',
      component: JoinUs,
    },
  ],

});
// router.beforeEach(route.beforeEach());
// router.afterEach(route.afterEach());
// // 解决联系跳转同一页面报错的bug
// const originalPush = Router.prototype.push;
// Router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch((err) => err);
// };

export default router;
